<template>
	<page-container class="index" :page="page">
		<heading-image v-if="page.headingImage" type="large">
			<hero-image>
				<div class="hero-logo-wrapper">
					<div class="hero-logo" />
					<h1>café bistro bar</h1>
				</div>
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>
		</heading-image>

		<main-content>
			<div class="intro-text align-center">
				<h2 v-if="page.subtitle" class="subtitle">
					{{ page.subtitle }}
				</h2>
				<div v-parse-links v-html="page.content" />
			</div>
		</main-content>

		<contentblocks :items="page.contentblocks" />
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();

await fetchPage();
</script>

<style lang="scss" scoped>
.hero-logo-wrapper {
	max-width: 600px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;

	.hero-logo {
		width: 305px;
		height: 217px;
		background: url('~/assets/images/logo-leo-full-large.png') no-repeat center center;
		background-size: 305px;
		margin: 0 auto;
	}

	h1 {
		color: #d7d2c4;
		font-size: 3em;
		margin: 10px 0;
		width: 100%;
		text-align: center;
	}
}

.intro-text {
	max-width: 680px;
	margin: 0 auto;
	color: #6a2b31;
}

@media (max-width: 1000px) {
	.hero-logo-wrapper {
		display: none;
	}
}
</style>
